import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';

import { PageStatus } from 'enums';
import {AuthAPI, RedemptionModeAPI} from "../../API";
import {Show} from "../../Layout";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import moment from 'moment'
import {exportToCSV, exportToExcel} from "../../Utils/ExportToExcel";
import {Confirmation} from "../../Shared/Confirmation";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import ManualApproval from "./ManualApproval";
import csvtojson from "csvtojson";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import {OtpVerify4RequestApproval} from '../Redemptions/OtpVerification4ApproveRequest';

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    redemptions: any[],
    queryId?: string | null,
    filteredData: any,
    filters: any,
    pageSize : any,
    currentPageNumber : any,
    loading: any,
};

 class List extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            redemptions: [],
            queryId: null,
            showManual: false,
            isManual: false,
            isXoXo: false,
            isBulkRejectRequest: false,
            id: '',
            bulkImportData: [],
            filteredData: [],
            currentPage: 1,
            pageSize: 10,
            modes: [],
            filters: {
                requestDate: '',
                redemptionModeTitle: '',
                redemptionRequestStatus: '',
                email: ''
            },
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : '',
            loading: false,
            showSingleApprovalOtpModal: false,
            setAmazoneApprovalModal: false,
            showAmazoneApprovalModal: false,
            bulkApprovalAmazoneState: false,
            bulkXoxoApprovalState: false,
            userPhoneNumber: '',
            confirmOtp: '',
        };
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

  applyFilters = () => {
    if (this.state.currentPageNumber == 1) { this.fetchList(); return; }
    this.setState({currentPageNumber : 1})
  };

    clearFilter = async () => {
        await this.setState({
            filters: {
                requestDate: '',
                redemptionModeTitle: '',
                redemptionRequestStatus: '',
                email: '',
                userId : ''
            },
            pageSize : 10,
            currentPageNumber : 1,
            totalResponseData : '',
            totalResponsePages : ''
        })
        this.fetchList();
    }


    componentDidMount() {
        this.fetchList()
        this.fetchModes()
    }

    fetchList(): Promise<void> {   
        const {pageSize, currentPageNumber, filters} = this.state;      
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.redemptionList(pageSize, currentPageNumber, filters))
            .then((users) => {
                let responseData: any = users; 
                this.setState({ redemptions: responseData.data, filteredData: responseData.data, totalResponseData: responseData.totalCount, totalResponsePages: responseData.totalPage, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

     fetchModes = () => {
         Promise.resolve()
             .then(() => this.setState({ status: PageStatus.Loading }))
             .then(() => RedemptionModeAPI.redemptionList(100))
             .then((surveyData: any) => {
                 if (!!surveyData) {
                     const options = surveyData.map((item) => ({
                         label: item.name,
                         value: item.name,
                     }));
                     this.setState({
                         modes: options,
                         status: PageStatus.Loaded
                     });
                 }
             })
             .catch((err) => {
                 this.setState({ error: err.message, status: PageStatus.Error });
             });
     };

    async handleExport(){
        const {pageSize, currentPageNumber, filters} = this.state; 
        this.setState({ loading: true });
        try {
            const recievedData = await AuthAPI.redemptionList('All', currentPageNumber, filters);
            exportToExcel(recievedData.data, 'redemptions');
        } catch (error) {
            console.error("Error exporting data:", error);
        }finally {
            this.setState({ loading: false });
        }
    };


    // @ts-ignore
     approvedActions(id, mobile): Promise<void> {
        if(mobile && this.state.confirmOtp) {
            let obj = {
                id, approvedById: this.props.userId, otp: this.state.confirmOtp, adminflag:"auth"
            }
            return Promise.resolve()
                .then(() => this.setState({status: PageStatus.Loading}))
                // .then(() => AuthAPI.approveRedemptionRequest(obj))
                .then(() => AuthAPI.ApprovePendingfinance(obj))
                .then((users) => {
                    alert('Request approved successfully');
                    this.setState({data: users, status: PageStatus.Loaded, userPhoneNumber:"", showSingleApprovalOtpModal: false, confirmOtp:""});
                    this.fetchList();
                })
                .catch((error) => {
                    this.setState({error: error.message, status: PageStatus.Error});
                });
        } else {
            alert(mobile ? "Wrong Otp Provided." : 'Mobile number not available, Please update mobile number')
        }
    }

    rejectActions(id): Promise<void> {
        let obj = {
            id, approvedById: this.props.userId, "type":"Admin"
        }
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.rejectRedemptionRequest(obj))
            .then((users) => {
                alert('Request rejected successfully');
                this.setState({ data: users,  status: PageStatus.Loaded });
                this.fetchList();
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }


     approvedActionsBulkManual(): Promise<void> {
          return Promise.resolve()
              .then(() => this.setState({status: PageStatus.Loading}))
              .then(() => AuthAPI.manualBulkApproveAdmin({bulkImportData: this.state.bulkImportData, otp: this.state.confirmOtp, adminflag:"auth", approvedById: this.props.userId}))
              .then((users) => {
                  this.fetchList()
                  alert('Manual Redemptions Approval Successfully Uploaded')
                  return this.setState({ status: PageStatus.Loaded, isManual:false, bulkApprovalAmazoneState: false, confirmOtp:""});
              })
              .catch((error) => {
                  this.setState({error: error.message, status: PageStatus.Error});
              });
     }


     approvedActionsBulkXoxo(): Promise<void> {
         return Promise.resolve()
             .then(() => this.setState({status: PageStatus.Loading}))
             .then(() => AuthAPI.xoxoBulkApproveadmin({bulkImportData: this.state.bulkImportData, otp: this.state.confirmOtp, adminflag:"auth", approvedById: this.props.userId}))
             .then((users) => {
                 this.fetchList()
                 alert('Manual Redemptions Approval Successfully Uploaded')
                 return this.setState({ status: PageStatus.Loaded, isXoXo:false, bulkXoxoApprovalState: false, confirmOtp:""});
             })
             .catch((error) => {
                 this.setState({error: error.message, status: PageStatus.Error});
             });
     }

    bulkRejectRedeemptionRequests(): Promise<void> {
        let rejectObject = {"rejectList":this.state.bulkImportData, approvedById: this.props.userId, "type":"Admin"};
        return Promise.resolve()
        .then(() => this.setState({status: PageStatus.Loading}))
        .then(() => AuthAPI.RejectRequestBulk(rejectObject))
        .then((respo) => {
            if(respo.message == "Success!"){
                this.fetchList();
                alert('Bulk Rejection Of Redemptions Requests Successfully Uploaded');
                return this.setState({ status: PageStatus.Loaded, isBulkRejectRequest:false});
            }
        })
        .catch((error) => {
            this.setState({error: error.message, status: PageStatus.Error});
        });
    }

     handleFileChange = async (event) => {
        this.setState({isManual: false, isXoXo: false, isBulkRejectRequest: false,})
         const file = event.target.files[0];
         if (file) {
             try {
                 const jsonData = await this.convertCsvToJson(file);
                 const transformedData = jsonData.map(this.modifiedConvertedJson);
                 this.setState({ bulkImportData:  transformedData })
                 console.log(transformedData);
             } catch (error) {
                 console.error('Error converting CSV to JSON:', error);
             }
         }
     };

     convertCsvToJson = (file: File): Promise<any[]> => {
         return new Promise((resolve, reject) => {
             const reader = new FileReader();

             reader.onload = () => {
                 const csvContent: any = reader.result

                 if (!csvContent.trim()) {
                     reject(new Error('CSV content is empty.'));
                     return;
                 }

                 csvtojson()
                     .fromString(csvContent)
                     .then(jsonData => resolve(jsonData))
             };

             reader.onerror = (error) => {
                 reject(error);
             };

             reader.readAsText(file);
         });
     };

     modifiedConvertedJson = (obj) => {
         if(obj.coupon && obj.pin && this.state.isManual === false) {
            this.setState({ isManual: true, bulkApprovalAmazoneState: true })
         } else if(obj.id && obj.type) {
            this.setState({ isBulkRejectRequest: true })
            return { id: obj.id, userId: obj.userId };            
         } else if(obj.id && !obj.coupon && !obj.pin && this.state.isXoXo === false) {
            this.setState({ isXoXo: true, bulkXoxoApprovalState: true })
         }
         return {
             id: obj.id,
             userId: this.props.userId,
             coupon: obj.coupon,
             pin: obj.pin,
             validity: obj.validity,
             approvedById: this.props.userId,
             bulkImportData: []
         };
     }

     handlePageSizeChange = (newValue : any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
     handlePageNumberChange = (newValue : any) => { this.setState({ currentPageNumber: newValue }); };
   
     componentDidUpdate(prevProps: {}, prevState: State) {
         if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
     };

    render() {
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state; 
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Redemption Requests</h4>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>
                <OtpVerify4RequestApproval
                    userId={this.props.userId}
                    show={this.state.showManual}
                    onClose={()=> this.setState({ showManual: false, id: '', showSingleApprovalOtpModal: false, bulkApprovalAmazoneState: false, bulkXoxoApprovalState: false, setAmazoneApprovalModal: false })}
                    onSubmit={(paramOtp) => {
                        this.setState({ showManual: false, confirmOtp: paramOtp})
                        if(this.state.showSingleApprovalOtpModal){
                            this.approvedActions(this.state.id, this.state.userPhoneNumber);
                        } else if(this.state.setAmazoneApprovalModal) {
                            this.setState({showAmazoneApprovalModal: true});
                        } else if (this.state.bulkApprovalAmazoneState) {
                            this.approvedActionsBulkManual();
                        } else if(this.state.bulkXoxoApprovalState) {
                            this.approvedActionsBulkXoxo();
                        }
                    }}
                />
                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='mb-3'>Filter</div>

                    <form>
                        <div className="row">
                            <div className="col">
                                <label>Request Date</label>
                                <input value={filters.requestDate} type="date" name="requestDate" onChange={this.handleFilterChange} className="form-control" placeholder="select date" />
                            </div>
                            <div className="col">
                                <label>Redemption Mode</label>
                                <select
                                    name='redemptionModeTitle'
                                    id='mode'
                                    value={filters.redemptionModeTitle}
                                    required
                                    onChange={this.handleFilterChange}
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        height: '40px',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                    }}
                                >
                                    <option value=''>--Choose--</option>
                                    {this.state.modes.length > 0 ? this.state.modes.map((item, index) => (
                                        <option key={index} value={item.label}>{item.label}</option>
                                    )) : ''}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>Request Status</label>
                                <select
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        height: '40px',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '5px',
                                        transition:
                                            'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                    }}
                                    id='gender'
                                    required
                                    name="redemptionRequestStatus"
                                    value={filters.redemptionRequestStatus}
                                    onChange={this.handleFilterChange}
                                >
                                    <option value=''>--Choose--</option>
                                    <option value='New'>New</option>
                                    <option value='Pending(Finance)'>Pending(Finance)</option>
                                    <option value='Redeemed'>Redeemed</option>
                                    <option value='Rejected'>Rejected</option>
                                </select>
                            </div>
                            <div className="col">
                                <label>User ID</label>
                                <input type="text" className="form-control" placeholder="User ID"
                                       name="userId"
                                       value={filters.userId}
                                       onChange={this.handleFilterChange}
                                />
                            </div>
                        </div>
                    </form>

                    <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                        <button type="button" className="btn btn-success" onClick={() => this.applyFilters()}>Filter Redemptions</button>
                        <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export</button>
                        <button type="button" className="btn btn-danger ml-1" onClick={() => this.clearFilter()}>Clear Filter</button>

                        <div>
                            <input className="mt-2" type="file" accept=".csv" onChange={(e) => this.handleFileChange(e)} />
                        </div>
                        <div>
                            {/* <Confirmation onAction={() => this.approvedActionsBulkManual()} body="Are you sure want to approve manual ?"> */}
                                <Button
                                    variant="primary"
                                    disabled={this.state.bulkImportData.length === 0 || this.state.isManual === false}
                                    className="mt-3"
                                    size="sm"
                                    onClick={() => this.setState({showManual: true})}
                                >
                                    Amazon Approve
                                </Button>
                            {/* </Confirmation> */}
                        </div>
                        <div>
                            {/* <Confirmation onAction={() => this.approvedActionsBulkXoxo()} body="Are you sure want to approve by xoxo ?"> */}
                                <Button
                                    variant="primary"
                                    disabled={this.state.bulkImportData.length === 0 || this.state.isXoXo === false}
                                    className="mt-3"
                                    size="sm"
                                    onClick={() => this.setState({showManual: true})}
                                >
                                    XoXo Approve
                                </Button>
                            {/* </Confirmation> */}
                        </div>
                        <div>
                            <Confirmation onAction={() => this.bulkRejectRedeemptionRequests()} body="Do you want to reject bulk requests ?">
                                <Button
                                    variant="primary"
                                    disabled={this.state.bulkImportData.length === 0 || this.state.isBulkRejectRequest === false}
                                    className="mt-3"
                                    size="sm"
                                >
                                    Bulk Reject Requests
                                </Button>
                            </Confirmation>
                        </div>
                    {/*<button type="button" className="btn btn-warning ml-1">Approve Redemption</button>*/}
                    </div>


                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    {/* <div className='mb-3'>Requests Details</div> */}
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>
                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.showAmazoneApprovalModal} >
                        <ManualApproval
                            id={this.state.id}
                            otp={this.state.confirmOtp}
                            userId={this.props.userId}
                            show={this.state.showAmazoneApprovalModal}
                            onClose={()=> this.setState({setAmazoneApprovalModal: false, showAmazoneApprovalModal: false, id: '' })}
                            onSubmit={() => {
                                this.fetchList()
                                this.setState({setAmazoneApprovalModal: false, showAmazoneApprovalModal: false, id: '', confirmOtp:"" })
                            }
                        }
                        />

                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>
                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.redemptions.length}>
                                At the current moment data is not available.
                            </Alert>
                        </Show>


                        <Show when={!!this.state.filteredData.length}>
                            <div className='mb-2'>
                                <ShowDataAccording2PageSize 
                                    currentPageSize={pageSize} 
                                    currentPage={currentPageNumber}
                                    sendPageSize={this.handlePageSizeChange}
                                    showTotalData={totalResponseData} 
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                                {this.state.loading && (
                                    <img style={{ height: "415px", }}
                                        src="../loadernew.gif"
                                        alt="Loading..."
                                    />
                                )}
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>User Id</th>
                                    <th>Points Redeemed</th>
                                    <th>Points Requested</th>
                                    <th>Mode</th>
                                    <th>Status</th>
                                    <th>Approved By</th>
                                    <th>Request Date</th>
                                    <th>Phone No/Data Card No/DTH No</th>
                                    <th>Coupon Code</th>
                                    <th>Pin</th>
                                    <th>Validity</th>
                                    <th>Action Date</th>
                                    <th>Action</th>
                                </tr>
                                </thead>

                                <tbody>
                                { filteredData.map((redemption, index) => (
                                    <tr key={redemption.id}>
                                        <td>{((pageSize)*(currentPageNumber-1))+(index + 1)}</td>
                                        <td>{redemption.userId}</td>
                                            <td>
                                          <span
                                              aria-label="button"
                                              role="button"
                                              tabIndex={0}
                                              className="text-primary"
                                              onKeyPress={() => null}
                                              onClick={() => {
                                                  this.setState({
                                                      formType: MODAL_TYPES.NONE,
                                                      queryId: redemption.id,
                                                  });
                                              }}
                                          >{redemption.pointsRedeemed}</span>
                                            </td>
                                            <td>{redemption.pointsRequested}</td>
                                            <td>{redemption.redemptionModeTitle}</td>
                                            <td>{redemption.redemptionRequestStatus}</td>
                                            {/* <td>{redemption.approvedById || redemption.cancelledById ? 'Admin' : 'Not Approved'}</td> */}
                                            <td>{redemption.approvedByRole }</td>
                                            <td>{moment(redemption.requestDate).format('MM/DD/YYYY HH:mm A')}</td>
                                            <td>
                                                {redemption.user
                                                    ? redemption.user.phoneNumber
                                                    : '-'}
                                            </td>
                                            <td>
                                                {redemption.coupon ? redemption.coupon : redemption.redemptionRequestStatus === 'Redeemed' ? 'Delivered' : '-'}
                                            </td>
                                            <td>
                                                {redemption.pin ? redemption.pin : redemption.redemptionRequestStatus === 'Redeemed' ? 'Delivered' : '-'}
                                            </td>
                                            <td>
                                                {redemption.validity ? redemption.validity : redemption.redemptionRequestStatus === 'Redeemed' ? 'Delivered' : '-'}
                                            </td>
                                            <td>{redemption.actionDate ? moment(redemption.actionDate).format('MM/DD/YYYY HH:mm A') : "-"}</td>
                                            <td>
                                                {redemption.redemptionModeTitle !== 'Amazon e-Gift Card' ?
                                                // <Confirmation onAction={() => this.approvedActions(redemption.id, redemption.user ? redemption.user.phoneNumber : null)} body="Are you sure want to approve request ?">
                                                    <Button
                                                        variant="success"
                                                        size="sm"
                                                        disabled={redemption.redemptionRequestStatus === 'Redeemed'
                                                            || redemption.redemptionRequestStatus === 'Rejected' || redemption.redemptionRequestStatus === "Pending(Finance)"}
                                                        className="mx-1"
                                                        onClick={() => this.setState({ id: redemption.id, showManual: true, showSingleApprovalOtpModal: true, userPhoneNumber : (redemption.user ? redemption.user.phoneNumber : null)})}
                                                    >
                                                        Approve
                                                    </Button>
                                                // </Confirmation>
                                                    :
                                                    <Button
                                                        variant="success"
                                                        size="sm"
                                                        disabled={redemption.redemptionRequestStatus === 'Redeemed'
                                                            || redemption.redemptionRequestStatus === 'Rejected' || redemption.redemptionRequestStatus === "Pending(Finance)"}
                                                        onClick={() => this.setState({ id: redemption.id, showManual: true, setAmazoneApprovalModal: true, userPhoneNumber : (redemption.user ? redemption.user.phoneNumber : null)})}
                                                        className="mx-1"
                                                    >
                                                        Amazon Approve
                                                    </Button>
                                                }

                                                <Confirmation onAction={() => this.rejectActions(redemption.id)} body="Are you sure want to reject request ?">
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        disabled={redemption.redemptionRequestStatus === 'Redeemed'
                                                            || redemption.redemptionRequestStatus === 'Rejected'
                                                            || redemption.redemptionRequestStatus === 'Failed'
                                                            || redemption.redemptionRequestStatus === "Pending(Finance)"
                                                    }
                                                        className="mx-1"
                                                    >
                                                        Reject
                                                    </Button>
                                                </Confirmation>
                                            </td>

                                        </tr>
                                    ))
                                }
                                </tbody>

                            </Table>
                                <div>
                                    <PaginationNumbering 
                                        currentPage={currentPageNumber} 
                                        returnPagenumber={this.handlePageNumberChange}
                                        showTotalData={totalResponseData} 
                                        sendLastPage={totalResponsePages}
                                    />
                                </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        userId: state.adminUser.adminUser.userId,
    };
}

const ListWithRouter = withRouter(connect(
    mapStateToProps,
)(List));

export { ListWithRouter as List };
